<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>
<script>
// import VConsole from 'vconsole';

export default {
  name: 'GoldBean',
  mounted() {
    const { path } = this.$route;
    if (path.includes('gold1')) {
      // new VConsole();
    }
  },
};
</script>
<style lang="scss" scoped>
.page {
  max-width: 750px;
  // height: 100%;
  margin: auto;
  min-height: 100%;
  background-color: #f6f6f6;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
<style lang="scss">
  @import '@/styles/variable';
  @import './scss/index';
</style>
